.breadcrumb {
    ul {
        display: block;
        min-height: unset;
        padding: 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-white--fixed;
        line-height: 1.75;

        a {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color $duration $timing;
            &:hover,
            &:focus {
                text-decoration-color: $color-second;
                color: $color-white--fixed;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-second;
            vertical-align: text-top;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .breadcrumb {
        ul {
            min-height: unset;
            padding: 0;
        }
        li svg {
            margin: 0;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        li svg {
            margin: 0 -1px;
        }
    }
}
