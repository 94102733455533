.home {
    .front-page {
        &__title {
            margin: 0;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            color: $color-dark;
            &::before {
                display: none;
            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .slideshow {
        height: 510px;
        position: relative;

        &__container {
            height: 100%;
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 60px;
            background-color: $color-dark--fixed;
            border-radius: $border-radius;
            width: 425px;
            padding: 30px;
            box-sizing: border-box;
            // position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                color: $color-white--fixed;
            }

            svg {
                width: 100px;
                height: 100px;
                fill: $color-second;
                position: absolute;
                bottom: -45px;
                right: -30px;
            }

            &.no-description {
                bottom: 15px;
            }
        }

        &__title {
            font-family: $font-family;
            font-size: toRem(24);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-white--fixed;
            margin-bottom: 5px;
        }

        &__description {
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(25);
            color: $color-white--fixed;
        }

        .swiper-container {
            height: 100%;
        }

        .c-pagination {
            @include absolute($bottom: 45px, $right: -14px);
            transform: translateX(-50%);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 15px;
            background-color: $color-second;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;
            backdrop-filter: blur(2.5px);

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-dark--fixed;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-dark--fixed;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-dark--fixed;
                    border: 1px solid $color-dark--fixed;
                    border-radius: $border-radius--round;
                    transition: {
                        property: background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus,
                    &-active {
                        background-color: transparent;
                        border-color: $color-dark--fixed;
                    }
                }
            }

            .hidden {
                display: none;
            }
        }

        img,
        video {
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 450px;
            border-radius: $border-radius;
        }

        &--video {
            img {
                display: none;
            }
        }

        &.no-description {
            height: 460px;

            .c-pagination {
                bottom: 0px;
            }
        }
    }

    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        margin-top: 80px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -385px;
            left: 0px;
            z-index: -1;
            width: 100%;
            height: 420px;
            background-color: $color-light;
        }

        &__container {
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-end;
            justify-content: flex-end;
            // On inverse tout le contenu afin de respecter la maquette tout en laissant le h2 au debut dans le twig
        }

        &__title {
            margin-left: 30px;
            font-family: $font-family;
            font-size: toRem(45);
            font-weight: $font-weight-bold;
            line-height: toRem(45);
            color: $color-dark;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(4, 200px);
            gap: 0 30px;
        }

        &__link {
            box-shadow: $shadow;
            background-color: $color-white;
            border-radius: $border-radius;
            border: 1px solid transparent;

            transition: {
                property: border-color, box-shadow;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                border-color: $color-light;
                box-shadow: none;

                .access__item {
                    &::before {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        &__item {
            padding: 25px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 155px;
            gap: 10px;

            &::before {
                content: "";
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 0;
                width: 25px;
                height: 25px;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: {
                    property: width, height, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            svg {
                width: 55px;
                height: 55px;
                position: relative;
                z-index: 1;
                fill: $color-dark;
                transition: fill 0.25s ease;

                path {
                    fill: $color-dark;
                }
            }

            p {
                font-family: $font-family-second;
                font-size: toRem(16);
                font-weight: $font-weight-bold;
                line-height: toRem(20);
                color: $color-dark;
                max-width: 111px;
                text-transform: uppercase;
                transition: color 0.25s ease;
            }
        }

        &.no-description {
            margin-top: 70px;
        }
    }

    // ======================================================================================================
    // Events
    //======================================================================================================

    .events {
        margin-top: 98px;
        position: relative;

        &__bg {
            position: absolute;
            top: 205px;
            left: 0px;
            z-index: 0;
            width: 100vw;
            height: 360px;
            background-color: $color-events-bg;
            overflow: hidden;
            pointer-events: none;
            user-select: none;

            svg {
                position: absolute;
                bottom: -167px;
                z-index: 0;
                left: 50%;
                transform: translateX(calc(-470px - 450px));
                width: 450px;
                height: 450px;
                opacity: 0.05;
                fill: $color-white--fixed;
            }
        }

        &__container {
            position: relative;
        }

        &__title {
            font-size: toRem(50);
            line-height: toRem(55);

            &--small {
                color: $color-white--fixed;
                margin: 15px 0 10px;
                padding: 0 10px;
                font-family: $font-family;
                font-size: toRem(24);
                font-weight: $font-weight-bold;
                line-height: toRem(30);
                width: 100%;
                box-sizing: border-box;
            }
        }

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            border-radius: $border-radius-medium;
            padding: 0;
            border: none;
            margin: 0;
            transition: all $duration ease-in-out;

            &:hover,
            &:focus {
                background-color: rgba($color-main, 0.1);

                svg {
                    fill: $color-dark;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-white--fixed;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &::after {
                display: none;
            }
        }

        &__item {
            &:hover,
            &:focus {
                .events__link {
                    .events__dates {
                        background-color: $color-white--fixed;
                    }
                }
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__content-container {
            margin-top: 20px;
        }

        &__image {
            width: 370px;
            height: 230px;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--notimg {
                background: url($urlSpriteImage + "/events-icons/events.svg") no-repeat $color-card-notimg--home;
                background-position: 50%;
                // z-index: 15;
            }
        }

        &__dates {
            background-color: $color-second;
            display: flex;
            align-items: center;
            border-radius: $border-radius-medium;
            padding: 10px 20px;
            width: fit-content;
            margin-right: 20px;
            margin-top: -25.5px;
            transition: background-color $duration $timing;

            p {
                span {
                    font-family: $font-family;
                    font-size: toRem(18);
                    font-weight: $font-weight-semibold;
                    line-height: toRem(25);
                    color: $color-dark--fixed;
                    text-transform: uppercase;
                }
            }

            svg {
                height: 25px;
                width: 25px;
            }
        }

        &__buttons {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }

        &__button {
            text-transform: uppercase;
            border-radius: $border-radius-medium;
            padding: 0 25px;
            display: flex;
            align-items: center;
            color: $color-white--fixed;
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            height: 45px;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &--proposer {
                background-color: rgba($color-white--fixed, 0.1);
                border: 2px solid transparent;

                &:hover,
                &:focus {
                    border-color: rgba($color-white--fixed, 0.2);
                }
            }

            &--tous {
                background-color: transparent;
                border: 2px solid $color-second;

                &:hover,
                &:focus {
                    background-color: $color-second;
                    color: $color-dark--fixed;
                }
            }
        }
    }

    // ======================================================================================================
    // News
    //======================================================================================================

    .news {
        margin-top: 160px;

        &__title {
            font-size: toRem(50);
            line-height: toRem(55);

            &--small {
                margin: 0;
                margin-top: 15px;
            }
        }

        // specific style for big item

        &__big-item {
            margin-top: 20px;
            height: 370px;

            .news__more {
                bottom: 20px;
                right: 20px;
            }

            &:hover,
            &:focus {
                .news__big-content {
                    border-color: $color-rgba-border;
                }
                .news__more {
                    background-color: $btn-tag-color;

                    svg {
                        fill: $color-white;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__big-link {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &__big-image {
            width: calc(50% + 85px);
            border-radius: $border-radius;
            overflow: hidden;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.news__image--notimg {
                background: url($urlSpriteImage + "/base-icons/posts-icons/post.svg") no-repeat $color-card-notimg--home;
                background-position: 50%;
            }
        }

        &__big-content {
            width: calc(50% - 35px);
            margin-left: -50px;
            background-color: $color-light;
            border-radius: $border-radius;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            border: 1px solid transparent;
            transition: border-color $duration $timing;

            &::before {
                content: "";
                position: absolute;
                bottom: 35px;
                right: 15px;
                z-index: 0;
                width: 15px;
                height: 15px;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }
        }

        &__big-title {
            margin: 15px 0 10px;
            font-family: $font-family;
            font-size: toRem(28);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-dark;
        }

        &__big-introduction {
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(25);
            color: $color-dark;
        }

        .swiper-wrapper {
            padding-bottom: 1px;
        }

        // end of specific style for big item

        &__more {
            position: absolute;
            bottom: 15px;
            right: 15px;
            z-index: 0;
            width: 25px;
            height: 25px;
            border: 1px solid $btn-tag-color;
            border-radius: $border-radius--round;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                fill: $btn-tag-color;
                width: 25px;
                height: 25px;
                transition: {
                    property: fill, transform;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__link {
            transition: none;
        }

        &__category {
            padding: 5px 15px;
            background-color: $color-main;
            text-transform: uppercase;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-semibold;
            line-height: toRem(20);
            color: $color-white--fixed;
            width: fit-content;
            border-radius: $border-radius-medium;
        }

        &__content-container {
            margin-top: 30px;
        }

        &__item {
            background-color: $color-light;
            padding: 30px;
            border-radius: $border-radius;
            box-sizing: border-box;
            height: auto;
            position: relative;
            border: 1px solid transparent;
            transition: border-color $duration $timing;

            &::before {
                content: "";
                position: absolute;
                bottom: 31px;
                right: 10px;
                z-index: 0;
                width: 15px;
                height: 15px;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }

            &:hover,
            &:focus {
                border-color: $color-rgba-border;

                .news__more {
                    background-color: $btn-tag-color;

                    svg {
                        fill: $color-white;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 28px;
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        &__button {
            margin: 0;
            color: $color-dark--fixed;

            &:hover,
            &:focus {
                background-color: $color-dark;
                color: $color-white;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            border-radius: $border-radius-medium;
            padding: 0;
            border: none;
            margin: 0;
            transition: all $duration ease-in-out;

            &:hover,
            &:focus {
                background-color: rgba($color-main, 0.1);

                svg {
                    fill: $color-dark;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-white--fixed;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &::after {
                display: none;
            }
        }
    }

    // ======================================================================================================
    // Kiosque
    //======================================================================================================

    .kiosque {
        margin-top: 100px;
        &__container {
            background-color: $color-kiosque-bg;
            border-radius: $br15;
            padding: 40px;
            display: flex;
            justify-content: space-between;
            max-height: 260px;
        }

        &__head {
            width: 330px;
        }

        &__content {
            width: 280px;
            max-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &__title {
            font-family: $font-family;
            font-size: toRem(50);
            font-weight: $font-weight-bold;
            line-height: toRem(55);
            color: $color-white--fixed;

            &--small {
                font-family: $font-family;
                font-size: toRem(24);
                font-weight: $font-weight-bold;
                line-height: toRem(30);
                color: $color-white--fixed;
                margin: 0;
            }
        }

        &__intro {
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(25);
            color: $color-white--fixed;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        &__template--button {
            background-color: transparent;
            color: $color-white--fixed;
            border: 2px solid $color-second;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: fit-content;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                color: $color-dark--fixed;
            }
        }

        &__item {
            display: flex;
            margin-top: 10px;
            gap: 37px;
        }

        &__image {
            width: 350px;
            height: 240px;
            border-radius: $br15;
            overflow: hidden;

            .swiper-slide {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }

                &--notimg {
                    background: url($urlSpriteImage + "/base-icons/documents-icons/documents.svg") no-repeat
                        $color-card-notimg--home;
                    background-position: 50%;
                }
            }
        }

        &__date {
            font-size: toRem(16);
            line-height: toRem(25);
            font-family: $font-family-second;
            font-weight: $font-weight-normal;
            color: $color-white--fixed;
            position: relative;
            margin-top: 5px;

            &::before {
                content: "";
                position: absolute;
                bottom: -20px;
                left: 0px;
                z-index: 0;
                width: 50px;
                height: 2px;
                border-radius: 50px;
                background-color: $color-second;
            }
        }

        &__buttons {
            margin-top: 40px;
            display: flex;
            gap: 10px;
        }

        &__button {
            width: 40px;
            height: 40px;
            background-color: $color-white--fixed;
            border-radius: $border-radius-medium;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
            }
        }
    }
}
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        // =====================================
        // Slideshow
        // =====================================
        .slideshow {
            height: 420px;

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
            }

            &__description {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            .c-pagination {
                right: -24px;
            }

            img,
            video {
                height: 360px;
            }

            &.no-description {
                height: 370px;

                .c-pagination {
                    bottom: 0px;
                }
            }
        }

        // =====================================
        // Access
        // =====================================

        .access {
            &::before {
                height: 380px;
                top: -330px;
            }

            &__title {
                margin-left: 20px;
                font-size: toRem(35);
                line-height: toRem(35);
            }

            &__list {
                grid-template-columns: repeat(4, 180px);
                gap: 0 20px;
            }

            &__link {
                &:hover,
                &:focus {
                    .access__item {
                        &::before {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            &__item {
                padding: 20px;
                height: 150px;
                gap: 6px;
                &::before {
                    width: 20px;
                    height: 20px;
                    left: 15px;
                    top: 30px;
                }

                svg {
                    width: 45px;
                    height: 45px;
                }

                p {
                    margin-top: 5px;
                }
            }
        }

        // ======================================================================================================
        // Events
        //======================================================================================================

        .events {
            &::before {
                top: 190px;
                height: 330px;
            }

            &__container {
                &::before {
                    left: -310px;
                    bottom: -280px;
                }
            }

            &__bg {
                top: 190px;
                height: 330px;
                svg {
                    transform: translateX(calc(-320px - 450px));
                    bottom: -196px;
                }
            }

            &__image {
                width: 300px;
                height: 200px;
            }

            &__title {
                font-size: toRem(45);
                line-height: toRem(50);

                &--small {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }

            &__link {
                width: 100%;
            }
        }

        // ======================================================================================================
        // News
        //======================================================================================================

        .news {
            &__big-item {
                height: 310px;
            }
            &__big-image {
                width: calc(50% + 80px);
                height: 100%;
            }

            &__big-content {
                width: 50%;
                margin-left: -80px;
                padding: 30px;

                &::before {
                    right: 10px;
                    bottom: 25px;
                }

                .news__more {
                    bottom: 10px;
                    right: 15px;
                }
            }

            &__big-title {
                font-size: toRem(25);
                line-height: toRem(30);
            }

            &__big-introduction {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            &__title {
                font-size: toRem(45);
                line-height: toRem(50);
                &--small {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }

            &__item {
                &::before {
                    right: 10px;
                    bottom: 30px;
                }
            }
        }

        // ======================================================================================================
        // Kiosque
        //======================================================================================================

        .kiosque {
            &__container {
                max-height: 245px;
                height: 245px;
            }

            &__title {
                font-size: toRem(45);
                line-height: toRem(50);

                &--small {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }

            &__item {
                gap: 30px;
                margin-top: 5px;
            }

            &__content {
                width: 230px;
                max-height: 160px;
            }

            &__head {
                width: 300px;
            }

            &__intro {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            &__image {
                width: 280px;
                height: 215px;
                margin-top: 5px;
            }

            &__date {
                font-size: toRem(14);
                line-height: toRem(20);
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .home {
        // =====================================
        // Slideshow
        // =====================================
        .slideshow {
            height: calc(270px + 85px);

            &__content {
                left: 40px;
                width: 450px;
            }

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
            }

            &__description {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            .c-pagination {
                bottom: 70px;
                right: -35px;
            }

            img,
            video {
                height: 270px;
            }

            &.no-description {
                height: 280px;

                .c-pagination {
                    bottom: 0px;
                }
            }
        }

        // =====================================
        // Access
        // =====================================
        .access {
            margin-top: 60px;

            &::before {
                height: 295px;
                top: -245px;
            }

            &__list {
                grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
                grid-auto-rows: minmax(155px, auto);
                gap: 20px 20px;
                width: 100%;
            }

            &__title {
                position: absolute;
                width: 0;
                font-size: 0.01rem;
                margin: 0;
            }

            &__item {
                p {
                    font-size: toRem(14);
                }
            }

            &.no-description {
                margin-top: 50px;
            }
        }

        // ======================================================================================================
        // Events
        //======================================================================================================

        .events {
            margin-top: 95px;
            &::before {
                top: 190px;
                height: 350px;
            }

            &__container {
                &::before {
                    left: -210px;
                    bottom: -260px;
                }
            }

            &__bg {
                svg {
                    transform: translateX(calc(-120px - 450px));
                }
            }

            &__item {
                width: calc(50% - 10px);
            }
            &__image {
                width: 100%;
                height: 220px;
            }

            &__title {
                &--small {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }

            &__buttons {
                justify-content: flex-end;
            }
        }

        // ======================================================================================================
        // News
        //======================================================================================================

        .news {
            &__big-link {
                flex-direction: column;
            }

            &__big-image {
                width: 100%;
                height: 320px;
            }

            &__big-content {
                width: calc(100% - 80px);
                margin-left: 0;
                padding: 30px;
                margin-top: -80px;
            }

            &__big-item {
                height: unset;
            }

            &__item {
                max-width: 100%;
                padding: 27px 30px;

                &::before {
                    bottom: 27px;
                    right: 6px;
                }
            }

            &__more {
                bottom: 13px;
                right: 12px;
            }

            &__buttons {
                margin-top: 32px;
            }
        }

        // ======================================================================================================
        // Kiosque
        //======================================================================================================

        .kiosque {
            &__container {
                flex-direction: column;
                height: fit-content;
                max-height: calc(100% - 20px);
                box-sizing: content-box;
            }

            &__head {
                width: 100%;
            }

            &__image {
                width: 300px;
                height: 215px;
                margin-bottom: -60px;
            }

            &__item {
                margin-top: 30px;
            }

            &__list {
                margin-top: -10px;
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .home {
        .access {
            &__link {
                height: 150px;
            }
            &__list {
                grid-template-columns: repeat(2, minmax(135px, 1fr));
                gap: 15px 20px;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .home {
        // =====================================
        // Slideshow
        // =====================================
        .slideshow {
            height: fit-content;
            .container {
                margin: 0;
                max-width: unset;
                padding: 0;
            }

            &__content-container.container {
                margin: 0 auto;
                max-width: 90%;
                padding: 0 10px;
            }

            &__content {
                left: unset;
                width: 100%;
                position: relative;
                margin-top: -31px;
                padding: 20px;
                z-index: 1;
                overflow: hidden;

                svg {
                    width: 70px;
                    height: 70px;
                    right: -20px;
                    bottom: -35px;
                }
            }

            .c-pagination {
                bottom: unset;
                top: 125px;
                right: -21px;
                height: 20px;
                gap: 8px;

                &__bullets {
                    gap: 8px;
                    .swiper-pagination-bullet {
                        width: 10px;
                        height: 10px;
                    }
                }

                .nav-swiper-autoplay {
                    width: 10px;
                    height: 10px;

                    &__icon {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            img,
            video {
                height: 180px;
                border-radius: 0;
                position: relative;
                z-index: -1;
            }

            &.no-description {
                height: 190px;

                .c-pagination {
                    bottom: 0px;
                }
            }
        }

        .access {
            &::before {
                height: 215px;
                top: -145px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .home {
        .events {
            margin-top: 75px;
            &::before {
                top: 190px;
                height: 305px;
            }

            &__image {
                height: 200px;
            }

            &__container {
                &::before {
                    display: none;
                }
            }

            &__buttons {
                flex-wrap: wrap;
                gap: 15px 15px;
            }
        }

        // .news {
        //     background-color: $color-white;
        //     margin-top: -80px;
        // }
    }
}

@media screen and (max-width: $small) {
    .home {
        .news {
            &__buttons {
                flex-wrap: wrap;
                gap: 20px;
            }

            &__big-image {
                height: 270px;
            }

            &__big-content {
                margin-top: -60px;
                padding: 20px 20px 45px;
                width: calc(100% - 60px);

                &::before {
                    width: 10px;
                    height: 10px;
                    bottom: 28px;
                    right: 12px;
                }

                .news__more {
                    bottom: 15px;
                }
            }

            &__container {
                box-sizing: content-box;
            }

            &__buttons {
                padding: 0 10px;
                margin-top: 22px;
                flex-direction: column;
            }

            &__button {
                width: 250px;
                box-sizing: border-box;
                height: 45px;
                padding: 0px 20px;
            }

            &__item {
                width: 100% !important;
                padding: 19px 20px;

                &::before {
                    width: 10px;
                    height: 10px;
                }
            }

            &__content-container {
                margin-top: 18px;
                padding: 0 10px;
            }

            &__more {
                width: 20px;
                height: 20px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            &__title {
                margin-left: 10px;
            }
        }

        .kiosque {
            margin-top: 80px;
            box-sizing: content-box;
            &__container {
                padding: 30px 25px;
                box-sizing: content-box;
            }
            &__item {
                flex-direction: column;
                gap: 0;
            }

            &__template--button {
                height: 45px;
                box-sizing: border-box;
            }

            &__image {
                width: 100%;
                height: 190px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        .events {
            &__bg {
                svg {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 610px) {
    .home {
        .events {
            &::before {
                height: 380px;
            }

            &__buttons {
                flex-direction: row;
                // display: block;
            }
        }

        .slideshow {
            &__content {
                overflow: hidden;
                position: relative;
                svg {
                    display: block;
                    width: 70px;
                    height: 70px;
                    right: -20px;
                    bottom: -35px;
                }
            }
        }

        .access {
            &::before {
                height: 370px;
                top: unset;
                bottom: 255px;
            }
        }
    }
}
