.nav-main--burger {
    z-index: inherit;
    height: 15px;
    width: 123px;
    height: 40px;
    border-radius: 10px;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 15px;
            @include size(fit-content);
            margin: 0;
            padding: 30px;
            background-color: $color-second;
            border: 0;
            border-radius: 0;
            transition: all $duration $timing;
            cursor: pointer;
            width: 123px;
            height: 40px;
            padding: 0;
            padding-left: 20px;
            border-radius: $border-radius-medium;
            gap: 18px;

            &__text {
                font-size: $font-size--5;
                color: $color-dark--fixed;
                text-transform: uppercase;
                font-family: $font-family-second;
                font-weight: $font-weight-semibold;
                margin-left: -10px;
                margin-top: -2px;
            }

            // Open menu
            &--open {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;

                &:hover,
                &:focus {
                    background-color: $color-dark;

                    span {
                        color: $color-white;
                    }

                    .nav-main-buttons__menu__icon {
                        background-color: $color-white;
                    }
                }

                span {
                    transition: {
                        property: background-color, border-color, color, opacity;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                // Burger menu Button icon
                .nav-main-buttons__menu__icon {
                    position: relative;
                    top: -1px;
                    display: block;
                    @include size(20px, 20px);
                    mask-image: url($urlSpriteImage + "base-icons/ic_menu.svg");
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: $color-dark--fixed;
                    transition: background-color $duration $timing;
                }
            }

            // Close menu
            &--close {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                width: 140px;
                padding: 0px 0 0 15px;
                position: absolute;
                right: 15px;
                top: -75px;

                svg {
                    fill: $color-dark--fixed;
                }

                &:hover,
                &:focus {
                    background-color: $color-white--fixed;
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }
    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        min-height: 490px;
        border-radius: 0 0 $border-radius $border-radius;
        width: 100%;
        background-color: $color-main-menu;
        overflow: hidden;
        z-index: 15;
        padding: 0;
        transition: {
            property: top, left;
            duration: 0.5s, $duration;
            timing-function: $timing;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -215px;
            left: 50%;
            transform: translateX(calc(-460px - 380px));
            z-index: 0;
            width: 380px;
            height: 380px;
            background-image: url($urlSpriteImage + "theme-icons/star.svg");
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.05;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-225px);
            width: 1px;
            height: 100%;
            background-color: #fff;
            opacity: 0.25;
        }

        // &::before {
        //     content: "";
        //     @include absolute($top: 0, $left: 0, $right: 20px);
        //     z-index: 1;
        //     height: 170px;
        //     background-color: $color-main;
        // }
    }

    // Section menu (for overflow)
    &__section {
        height: 100%;
        padding: 110px 0 20px;
    }

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start !important;
        position: relative;
        max-height: 360px;
    }

    // if Burger menu open
    &.js-open-menu {
        // Burger menu Button
        .nav-main-buttons__menu {
            bottom: -7px;

            // Close menu
            &--close {
                position: absolute;
                right: 15px;
                top: -75px;
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
                z-index: 16;
                // display: none;
            }

            // Open menu
            &--open {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top 0.7s cubic-bezier(0.4, 0, 0.2, 1);
            box-shadow: $shadow;
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
            opacity: 1;
        }
    }

    // Main menu
    .menu {
        // display: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 50px;
        @include size(40%, auto);
        margin-bottom: 40px;
        padding: 0 50px 0 0;
        z-index: 15;
        opacity: 0;
        transition: opacity 0.6s $timing 0.4s;

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;

            &.item-not-active,
            &.item-not-active--hover {
                .menu__link {
                    opacity: 0.7;

                    svg {
                        fill: $color-white--fixed;
                    }
                }
            }

            &.menu-item-has-children {
                .menu__link {
                    cursor: pointer;
                    &:hover,
                    &:focus {
                        &::after {
                            color: $color-white;
                        }
                    }
                }

                &.js-open-submenu {
                    .menu__link {
                        opacity: 1;

                        svg {
                            fill: $color-second;
                        }
                    }
                    .submenu {
                        display: block;
                        opacity: 1;
                        z-index: 1;
                        pointer-events: all;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            text-transform: uppercase;
            padding: 0;
            padding-left: 35px;
            font-family: $font-family;
            font-size: toRem(22);
            font-weight: $font-weight-extrabold;
            line-height: toRem(30);
            color: $color-white--fixed;
            position: relative;
            opacity: 1;
            transition: opacity $duration $timing;
            cursor: pointer;

            &[href="#"] {
                cursor: pointer;
                text-decoration: none;

                &:focus {
                    outline: none;
                }
            }

            svg {
                position: absolute;
                top: 50%;
                left: 0px;
                z-index: 0;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                fill: $color-second;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                opacity: 1;

                svg {
                    fill: $color-second;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        // display: none;
        position: absolute;
        z-index: 0;
        top: 0;
        z-index: inherit;
        width: fit-content;
        background-color: $color-bg--transparent;
        box-shadow: none;
        left: 420px;
        opacity: 0;
        // display: none;
        transition: opacity $duration $timing;
        pointer-events: none;

        &__nav {
            display: grid;
            grid-template-columns: repeat(2, 355px);
            gap: 0 40px;
            max-width: inherit;
            max-height: 80vh;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;
            position: relative;
            display: flex;
            max-height: 60px;

            &--grandchild {
                display: none;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                z-index: 0;
                width: 26px;
                height: 26px;
                mask: url($urlSpriteImage + "base-icons/ic-nav-chevron-right.svg") no-repeat center;
                background-color: $color-white--fixed;
                z-index: 1;
                transition: background-color $duration $timing;
            }

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0px;
                z-index: 0;
                width: 25px;
                height: 25px;
                border-radius: $border-radius--round;
                background-color: transparent;
                transition: background-color $duration $timing;
            }

            &:hover,
            &:focus {
                &::before {
                    background-color: $color-dark--fixed;
                }

                &::after {
                    background-color: $color-second;
                }
                .submenu__link {
                    padding-left: 10px;
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            padding: 15px 0;
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            line-height: toRem(25);
            color: $color-white--fixed;
            padding-right: calc(355px - 319px);
            transition: padding-left $duration $timing;
            padding: 12.5px 36px 12.5px 0;
            width: 100%;
            z-index: 2;

            &--child {
                &:hover,
                &:focus {
                    &::before {
                        width: 100%;
                    }
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;
            }
        }
    }
}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}

//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================

// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    position: relative;
    z-index: 11;
    width: fit-content;
    height: auto;
    max-width: 85%;
    min-height: 90px;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

body.js-open-menu {
    .q-access {
        right: -960px;
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main--burger {
        &.js-open-menu .nav-main-buttons__menu--close {
            right: 10px;
            top: -75px;
            border: none;
            border-radius: $border-radius-medium;

            svg {
                width: 32px;
                height: 32px;
            }
        }

        &__popup {
            &::before {
                transform: translateX(calc(-360px - 380px));
            }

            &::after {
                transform: translateX(-170px);
            }
        }
        // Main menu
        .menu {
            width: 275px;
            padding: 0 0px 0 0;

            &__link {
                font-size: toRem(20);
                height: 25px;
            }
        }

        // Sub menu
        .submenu {
            left: 350px;

            &__nav {
                grid-template-columns: repeat(2, 280px);
                gap: 0 30px;
            }

            &__link {
                font-size: toRem(14);
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-open-menu .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;
        width: unset;

        // if Burger menu open
        &.js-open-menu {
            // Popup menu
            .nav-main--burger__popup {
                bottom: 0;
                transition: bottom $duration $timing;
                top: unset;
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }

            .nav-main-buttons {
                &__menu--close {
                    top: 0;
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);

            // Open menu
            &__menu {
                padding: 0 0 0 11px;
                &--open {
                    @include relative($top: auto, $right: auto);
                    width: fit-content;
                    transition: none;
                    padding: 0 20px;
                    // width: 40px;
                }

                // Close menu
                &--close {
                    @include absolute($bottom: 0, $right: 0, $top: 0);
                    @include flex($alignItems: center, $justifyContent: center);
                    @include size(40px);
                    min-height: inherit;
                    margin: 40px 29px 0 auto;
                    padding: 0;
                    border-radius: $border-radius--round;
                    border: $btn-border-width $btn-border-style $color-white;

                    .nav-main-buttons__menu__text {
                        display: none;
                    }
                }
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 0, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(40px);
                margin: 40px auto 0 40px;
                padding: 0;
                background-color: $color-second;
                border-radius: $border-radius-medium;
                transition: left $duration $timing;

                svg {
                    @include size(30px);
                    fill: $color-dark--fixed;
                }

                &:hover,
                &:focus {
                    border: none;
                    background-color: $color-white--fixed;
                }
            }
        }

        // Popup menu
        &__popup {
            right: inherit;
            bottom: -100vh;
            padding: 0;
            height: 100dvh;
            transition: bottom $duration $timing;
            top: unset;
            border-radius: 0;

            &::before {
                height: 100px;
            }

            &::after {
                display: none;
            }
        }

        // Section menu
        &__section {
            padding: 0;

            &::before {
                background-image: url($urlSpriteImage + "theme-icons/star.svg");
                background-repeat: no-repeat;
                background-size: contain;
                bottom: -136px;
                content: "";
                height: 380px;
                left: -180px;
                opacity: 0.05;
                position: absolute;
                width: 380px;
                z-index: 0;
            }
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;

            &::before {
                display: none;
            }
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 125px 40px 40px;
            gap: 50px;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {
                    &::after {
                        display: none;
                    }

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-open-submenu {
                        .submenu {
                            left: 0;
                            display: flex;
                            opacity: 1;

                            .nav-main-buttons__submenu {
                                left: 0;
                            }
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                opacity: 1;

                svg {
                    fill: $color-second;
                }

                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 0 0;
            background-color: $color-main;
            transition: left $duration $timing;
            opacity: 1;

            &::after {
                display: none;
            }

            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px 40px 40px;
            }

            &__item {
                width: 100%;
            }

            &__link {
                width: 100%;
                font-size: toRem(16);

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin
    .admin-bar {
        .nav-main--burger {
            .menu,
            .submenu {
                padding-top: 132px;
            }

            &__popup::before {
                height: 132px;
            }
        }
    }
}

// 782 -- For admin bar only
@media screen and (max-width: 782px) {
    // Admin
    .admin-bar {
        .nav-main--burger {
            .menu,
            .submenu {
                padding-top: 146px;
            }

            &__popup::before {
                height: 146px;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .nav-main--burger {
        .nav-main-buttons {
            &__menu--open {
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__menu--close {
                margin: 40px 0px 0 auto;
            }

            &__menu__text {
                display: none;
            }
        }

        .menu {
            padding: 110px 0;
        }
    }
}
