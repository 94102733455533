.cover {
    margin-bottom: 60px;
    &__container {
        display: flex;
        align-items: center;
    }

    &__image-wrapper {
        width: calc(50% + 15px);
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        height: 360px;

        img {
            width: 100%;
            object-fit: cover;
            display: flex;
            height: 100%;
        }
    }

    &__image-wrapper ~ .cover__informations {
        width: calc(50% + 45px);
        margin-left: -60px;
    }

    &__informations {
        width: 100%;
        margin-left: 0;
        background-color: $color-main;
        border-radius: $border-radius;
        padding: 40px;
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: -50px;
            right: -30px;
            width: 100px;
            height: 100px;
            mask-image: url($urlSpriteImage + "/theme-icons/star.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            background-color: $color-second;
        }
    }

    &__title {
        margin: 15px 0;
        font-size: toRem(40);
        line-height: toRem(45);
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    &__intro {
        font-family: $font-family-second;
        font-size: toRem(18);
        font-weight: $font-weight-bold;
        line-height: toRem(25);
        color: $color-white;
    }

    &__title,
    &__intro {
        color: $color-white--fixed;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &__container {
            flex-direction: column;
        }

        &__image-wrapper {
            width: 100%;
            height: 270px;

            picture {
                width: 100%;
            }
        }

        &__image-wrapper ~ .cover__informations {
            width: calc((620 / 700) * 100%);
            margin-top: -80px;
            margin-left: 0;
        }

        &__informations {
            width: 100%;
            margin-top: 0x;
            padding: 30px;
        }

        &__informations-container {
            padding: 20px 10px;
        }

        &__title {
            font-size: toRem(35);
            line-height: toRem(40);
            margin: 15px 0;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__container {
            padding: 0;
            margin: 0;
            max-width: unset;
        }

        &__image-wrapper {
            height: 180px;
            border-radius: 0;
        }

        &__informations {
            width: 100%;
            max-width: 90%;
            padding: 0 10px;
        }
    }
}
