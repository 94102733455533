//===================================================================
// Google translate - GTranslate.io
//===================================================================

.tools {
    &__translate {
        position: relative;
        width: 40px;
        height: 40px;

        &__wrapper {
            @include absolute($left: 50%, $top: 65px);
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            z-index: 1;
            box-shadow: $shadow;

            &.js-open {
                display: flex;
            }

            &::before {
                content: "";
                @include absolute($top: -6px, $left: 50%);
                display: block;
                @include size(30px);
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            @include absolute($bottom: -10px, $left: 50%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            @include size(28px);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size(26px);
                fill: $color-dark--fixed;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-white--fixed;
                border-color: $color-white--fixed;

                svg {
                    fill: $color-main;
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// // 1200
// @media screen and (max-width: $large) {

// }

// // 960
// @media screen and (max-width: $medium) {

// }

// 640
@media screen and (max-width: $small) {
    .tools {
        &__translate {
            &__wrapper {
                top: -270px;

                &::before {
                    top: unset;
                    bottom: -6px;
                }
            }

            &__close {
                bottom: unset;
                top: -15px;
            }
        }
    }
}
