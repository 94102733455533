.card {
    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.contacts,
        &.contacts_category {
            background-image: url($urlSpriteImage + "/contacts-icons/contacts.svg");
        }
    }

    &--contact .card__container {
        &:hover,
        &:focus {
            background-color: $card--bg--hover;
            box-shadow: $card--shadow--hover;
            border: 1px solid rgba($color-main, 0.2);

            .card {
                &__category {
                    background-color: $taxo-bg--hover;
                    border-color: $taxo-border-color--hover;
                    color: $taxo-color--hover;
                }
            }
        }
    }
}
