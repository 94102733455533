//======================================================================================================
// Bloc Compte à rebours
//======================================================================================================
.bloc-countdown {
    margin: 30px 0;
    text-align: center;

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        @include font-size(22);
        color: $color-text;
    }

    &__date {
        @include flex($alignItems: center, $justifyContent: center);
        margin: 30px auto;

        &--past {
            .bloc-countdown {
                &__date {
                    &-bloc {
                        opacity: 0.5;
                    }
                }
            }

            + .bloc-countdown {
                &__message {
                    display: flex;
                }
            }
        }

        &-bloc {
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            @include size(150px);
            background-color: $color-third;
            border-radius: $border-radius;
            margin: 0 15px;
        }

        &__number {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-dark--fixed;
            @include font-size(34);
            line-height: 2.5rem;
        }

        &__text {
            color: $color-dark--fixed;
            @include font-size(18);
            text-transform: uppercase;
        }
    }

    &__message {
        display: none;
        flex-direction: column;
        align-items: center;

        ol,
        ul {
            @include flex($direction: column, $alignItems: center);
        }

        &--visible {
            display: flex;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .bloc-countdown {
        &__date {
            &-bloc {
                @include size(140px);
                margin: 0 10px;
            }
        }
    }
}

//640
@media screen and (max-width: $small) {
    .bloc-countdown {
        &__date {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 15px;

            &-bloc {
                width: auto;
                flex: 1;

                &:nth-child(2n + 1) {
                    margin: 0;
                }

                &:nth-child(2n) {
                    margin: 0;
                }
            }
        }
    }
}

//550
@media screen and (max-width: 550px) {
    .bloc-countdown {
        &__date {
            &-bloc {
                flex: 1 1 35%;
            }

            // :has() https://la-cascade.io/articles/utiliser-css-has // Add 06/2023
            // Not supported on firefox for now
            // Highlight days (when date is selected: days / hours / minutes)
            &:has(> :last-child:nth-child(3)) {
                /* 3 elements */
                .bloc-countdown__date-day {
                    flex: 1 1 100%;
                }
            }
            // Other conditions for testing
            // &:has(> :last-child:nth-child(1)) { /* 1 element */
            //     background-color: red;
            // }
            // &:has(> :last-child:nth-child(2)) { /* 2 elements */
            //     background-color: blue;
            // }
            // &:has(> :last-child:nth-child(4)) { /* 4 elements */
            //     background-color: purple;
            // }
        }
    }
}
