//======================================================================================================
// Bloc Image
//======================================================================================================
.bloc-image {
  display: flex;
  margin: 30px 0;

  &__container {
    position: relative;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    border-radius: $border-radius--image;
    overflow: hidden;

    a {
      position: relative;
      line-height: 0;

      &[target="_blank"]::before {
        content: "";
        @include absolute($top: 10px, $right: 10px);
        background-color: $color-white;
        border-radius: $border-radius--round;
        @include size(20px);
      }

      @extend %external_link;
      &::after {
        @include absolute($top: 10px, $right: 10px);
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__caption {
    position: absolute;
    bottom: 20px;
    max-width: 100%;
    font-family: $font-family-second;
    padding: 10px 40px;
    background-color: $color-bg-caption;
    border-radius: $border-radius-caption;
    color: $color-caption;
    pointer-events: none;
  }

  &.original {
    img {
      @include size(100%);
      object-fit: cover;
      object-position: top;
    }
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}
