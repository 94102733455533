.header {
    position: relative;
    height: 130px;
    z-index: 15;

    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }

    &__content {
        margin-top: 10px;
        &-container {
            margin: 0;
            padding: 0;
            max-width: unset;
            align-items: center;
            display: flex;
            gap: 35px;
            height: unset;
        }
    }
}

//======================================================================================================
// Header Fixed
//======================================================================================================

@keyframes headerFixed {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    animation: 1s headerFixed;
    // transition: 0.35s;
    box-shadow: $shadow;
}

.header--fixed ~ main {
    margin-top: 130px;
}

main {
    transition: none;
    transition-property: none !important;
}
//======================================================================================================
// Identity
//======================================================================================================

.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content, 100%);
    color: $color-text;
    transition: color $duration $timing;

    &:hover,
    &:focus {
        color: $color-second;
    }

    &__title {
        margin: 0;
        font-family: $font-family--heading;
        font-size: $font-size--1;
        font-weight: $font-weight--heading;
        line-height: $line-height--heading;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        align-items: center;

        svg {
            fill: $color-dark;

            path {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    &__item {
        width: 40px;
        height: 40px;
        background-color: $color-main;
        border-radius: $border-radius-medium;
        border: none;
        padding: 0;
        margin: 0;
        transition: background-color $duration $timing;

        svg {
            fill: $color-white--fixed;
        }

        &--accessibility {
            svg {
                height: 40px;
                width: 40px;
            }
        }

        #accessconfig {
            height: 40px;
        }

        &--search {
            width: unset;
            padding: 0 15px;
            gap: 9px;
        }

        &--translate {
            svg {
                width: 40px;
                height: 40px;
            }
        }

        &--mode {
            position: relative;
            margin: 0;
            padding: 0;

            input {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 2;

                &:hover {
                    cursor: pointer;
                }

                &:checked {
                    .tools__item--mode__dark {
                        opacity: 1;
                    }
                }
            }

            &__light,
            &__dark {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                margin: 0;
                padding: 0;
                border: 0;
                background-color: $color-bg--transparent;
                transition: {
                    property: opacity, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }

                svg {
                    width: 40px;
                    height: 40px;
                    transition: {
                        property: fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }
            }

            &__light {
                opacity: 0;
            }

            &.is-dark {
                .tools__item--mode__light {
                    opacity: 1;
                }

                .tools__item--mode__dark {
                    opacity: 0;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-dark;
                border-color: $color-white;
                border: none;

                .tools__item--mode__light,
                .tools__item--mode__dark {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &--search {
            svg {
                width: 20px;
                height: 20px;
            }

            span {
                color: $color-white--fixed;
                margin-bottom: 2px;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover,
            &:focus {
                span {
                    color: $color-white;
                }
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
// @media screen and (max-width: $large) {
// }

// 960
@media screen and (max-width: $medium) {
    .header-top {
        &::after {
            content: "";
            display: block;
            position: absolute;
            background-color: $color-bg--neutral;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: -1;
        }
    }

    .header {
        &__content {
            .container {
                gap: 15px;
            }
        }
    }

    // Identity
    .identity {
        display: flex;
        min-height: 90px;
        height: auto;

        &__title {
            svg {
                margin-top: 12px;
                height: 55px;
                width: 196px;
            }
        }
    }

    // Tools
    .tools {
        position: relative;
        width: 100%;
        top: 0;
        margin-top: 5px;

        &::before {
            position: relative;
            width: 100%;
        }

        &__container {
            display: flex;
            justify-content: flex-end;
            position: relative;
            top: -3px;
        }

        &__item {
            &--search {
                width: 40px;
                height: 40px;
                padding: 0;
                span {
                    display: none;
                    padding: 0;
                }
            }
        }

        &__content {
            margin: 0;
        }
    }

    // .tools-view {
    //     top: calc(100% + 5px);
    // }
}

// 640
@media screen and (max-width: $small) {
    .header {
        height: 95px;

        .identity {
            margin: 0 auto;
            padding: 0 10px;
            max-width: 90%;
        }

        &__content {
            z-index: 1;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100vw;
            height: 70px;
            background-color: $color-white;
            border-radius: $border-radius $border-radius 0 0;
            box-shadow: $shadow;

            .container {
                height: 100%;
                max-width: 90%;
                margin: 0 auto;
                padding: 0 10px;
            }
        }
    }

    .header--fixed {
        position: static;
    }
    // Tools
    .tools {
        width: 190px;
    }

    // .tools-view {
    //     &__container {
    //         .profils {
    //             margin-top: 80px;
    //             margin-right: 35px;
    //         }
    //     }
    // }
}
