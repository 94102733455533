.bloc-panoramic {
    position: relative;
    @include flex($alignItems: center);
    @include size(100%, 530px);
    margin: 40px 0;

    &--content {
        &::after {
            content: "";
            @include absolute($top: 0, $left: 0);
            z-index: 0;
            @include size(100%);
            pointer-events: none;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.09) 30%, rgba(0, 0, 0, 0.45) 80%);
        }
    }

    picture,
    img {
        @include size(100%);
        object-fit: cover;
        object-position: top;
    }

    &__container,
    &__content {
        @include absolute($top: 0, $bottom: 0, $left: 15px, $right: 0);
    }

    &__content {
        z-index: 1;
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
        gap: 10px;
        max-width: 400px;
        color: $color-white--fixed;
    }

    &__title {
        margin: 0;
        padding: 0;
        border: 0;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: toRem(35);
        line-height: toRem(40);
        text-shadow: 0px 0px 30px 0px #282828;

        &::before,
        &::after {
            display: none;
        }
    }

    &__desc {
        font-size: toRem(16);
        line-height: toRem(25);
        text-shadow: 0px 0px 30px #282828;
        font-family: $font-family-second;
    }

    &__button {
        margin: 10px 0 0 0;
        color: $color-white--fixed;
        background-color: $color-main;
        border-color: $color-main;

        &:hover,
        &:focus {
            background-color: $color-white--fixed;
            border-color: $color-white--fixed;
            color: $color-dark--fixed;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .bloc-panoramic {
        height: 410px;

        &__content {
            max-width: 430px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .bloc-panoramic {
        &__desc {
            font-size: $font-size--text;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .bloc-panoramic--content::after {
        background: linear-gradient(270deg, rgba($color-black, 0.27) 30%, rgba($color-black, 0.45) 80%);
    }
}
