.card {
    &__container {
        // Events v1
        &--event-v1 {
            gap: $card-event-v1-gap;
            padding: $card--padding;

            .card__content {
                padding: $card-content--padding;
            }
        }
    }

    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        padding: 20px 20px;
        background-color: $card-date-v1--bg;
        margin: 20px;
        border-radius: $br15;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100px;
            color: $card-date--color;
            font-family: $font-family;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_day {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: toRem(28);
            line-height: $line-height--heading;
        }

        &_month {
            font-size: toRem(18);
            text-transform: uppercase;
            margin-top: 5px;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $card-date--color;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.events,
        &.events_category {
            background-image: url($urlSpriteImage + "/events-icons/events.svg");
        }
    }

    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {
            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                padding: 0;
                background-color: $card-date-v2--bg;
                margin: 10px;
                padding: 0 20px;
                height: 45px;

                p {
                    width: unset;
                    height: unset;
                    color: $card-date--color;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .card__date_day,
                    .card__date_month {
                        font-family: $font-family;
                        font-size: toRem(18);
                        font-weight: $font-weight-semibold;
                        line-height: toRem(25);
                        color: $color-white--fixed;
                        margin: 0;
                    }
                }

                svg {
                    transform: rotate(-90deg);
                    box-sizing: inherit;
                    padding: 0;
                    fill: $card-date--color;
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================
@media screen and (max-width: $large) {
    .card {
        &--square {
            .card__date p span {
                font-size: toRem(16) !important;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {
            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;

                p {
                    width: 85px;
                }
            }
        }

        &--square {
            .card__date p span {
                font-size: toRem(14) !important;
            }
        }

        &__container--event-v1 {
            .card__date {
                padding: 20px 5px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {
            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0;

                &__many {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    width: auto;
                    padding: 15px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }
    }
}
