//===============================================
// Actions buttons
//===============================================
.documents-details__button {
  @include flex($alignItems: center);
  column-gap: 10px;
  margin: 0;
  font-family: $font-family-second;
  cursor: pointer;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: $font-size--text;
  line-height: $line-height;
  text-transform: uppercase;
  color: $card--color;

  svg {
    @include size($btn-action-size);
    background-color: $btn-action-bg;
    border: $btn-action-width $btn-action-style $btn-action-border-color;
    border-radius: $btn-action-border-radius;
    fill: $btn-action-color;
    transition: {
      property: background-color, border-color, fill;
      duration: $duration;
      timing-function: $timing;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;

    svg {
      background-color: $btn-action-bg--hover;
      border-color: $btn-action-border-color--hover;
      fill: $color-white--fixed;
    }
  }
}
