html {
    --color-black: #000000;
    --color-dark: #001132;
    --color-gray: #001132;
    --color-light: #eef2f8;
    --color-white: #ffffff;

    --color-text: #001132;
    --color-bg--body: #ffffff;
    --color-btn-tag: #002b6a;

    --color-main--menu: #002b6a;
    --color-kiosque-bg: #001132;
    --color-valo-bg: #eef2f8;
    --color-vignettes-link: #eef2f8;
    --color-border-filter: #002b6a;
    --color-accessconfig-bullet: #001132;
    --color-ablbum-nav--hover: #001132;
    --color-btn-contact: #002b6a;
    --shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.05);
    --color-events-bg: #002b6a;
    --color-bullet-1: #002b6a;
    --color-main-bg-dark: #002b6a;
    --color-rgba-border: rgba(0, 43, 106, 0.2); // Exemple de valeur rgba en mode clair
    --color-card-notimg--home: #eef2f8;

    &[data-theme="dark"] {
        --color-black: #000000;
        --color-dark: #ffffff;
        --color-gray: #fff;
        --color-light: #0c1c3c;
        --color-white: #001132;

        --color-text: #fff;
        --color-bg--body: #001132;
        --color-btn-tag: #fff;

        --color-main--menu: #002b6a;
        --color-kiosque-bg: #0c1c3c;
        --color-valo-bg: #0c1c3c;
        --color-vignettes-link: #001132;
        --color-border-filter: #eef2f8;
        --color-accessconfig-bullet: #f4921e;
        --color-btn-contact: #001132;
        --color-events-bg: #0c1c3c;
        --color-bullet-1: #fff;
        --color-main-bg-dark: #0c1c3c;
        --color-card-notimg--home: #253350;

        --shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.25);
        --color-rgba-border: rgba(255, 255, 255, 0.2);
    }
}

// Darkmode specifics colors
$color-main-menu: var(--color-main--menu);
$color-kiosque-bg: var(--color-kiosque-bg);
$color-valo-bg: var(--color-valo-bg);
$color-vignettes-link: var(--color-vignettes-link);
$color-border-filter: var(--color-border-filter);
$color-accessconfig-bullet: var(--color-accessconfig-bullet);
$color-btn-contact: var(--color-btn-contact);
$color-events-bg: var(--color-events-bg);
$color-bullet-1: var(--color-bullet-1);
$color-main-bg-dark: var(--color-main-bg-dark);
$color-rgba-border: var(--color-rgba-border); // Variable SCSS pour la valeur rgba
$color-card-notimg--home: var(--color-card-notimg--home);
