//======================================================================================================
// Quick Access
//======================================================================================================
.q-access {
    position: fixed;
    top: 215px;
    right: calc(-220px + 65px);
    border-radius: 15px 0 0 15px;
    z-index: 15;
    overflow: hidden;
    width: 220px;
    transition: right $duration $timing;
    box-shadow: $shadow-dark;

    &:hover,
    &:focus {
        right: 0;
    }

    &__container {
        margin: 0;
        max-width: unset;
        padding: 0;
    }

    &__list {
        width: 100%;
    }

    &__item {
        background-color: $color-main;
        height: 80px;
        transition: {
            property: background-color, border-color, color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &:nth-child(1),
        &:nth-child(2) {
            border-bottom: 1px solid $color-white--fixed;
        }

        &:hover,
        &:focus {
            background-color: $color-main-hover;

            .q-access__link {
                color: $color-white--fixed;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(20);
        color: $color-white--fixed;
        height: 100%;
        margin-left: 20px;
        text-transform: uppercase;
        gap: 15px;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-white--fixed;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

@media screen and (max-width: $large) {
    .q-access {
        top: 175px;
    }
}

// 960
@media screen and (max-width: $medium) {
    .q-access {
        position: static;
        width: 100vw;
        border-radius: 0;

        &__list {
            display: flex;
        }

        &__item {
            border-bottom: none;
            height: 60px;
            width: calc(100% / 3);

            // p {
            //     display: none;
            // }

            &:nth-child(1),
            &:nth-child(2) {
                border-right: 1px solid $color-white--fixed;
                border-bottom: none;
            }
        }

        &__link {
            justify-content: center;
            margin-left: 0;

            svg {
                height: 27px;
                width: 27px;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .q-access {
        &__item {
            p {
                display: none;
            }
        }
    }
}
