body:not(.home) .footer {
    margin-top: 60px;
}

.footer {
    background-color: $color-events-bg;
    margin-top: 110px;
    overflow: hidden;

    &__container {
        display: flex;
        gap: 100px;
        align-items: center;
        padding: 80px 15px 75px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -70px;
            right: -215px;
            z-index: 0;
            width: 185px;
            height: 185px;
            mask-image: url($urlSpriteImage + "/theme-icons/star.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            background-color: $color-second;
        }
    }

    &__infos {
        width: 300px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__opening-hours {
        width: 300px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__links {
        width: 228px;
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(24);
        font-weight: $font-weight-bold;
        line-height: toRem(30);
        color: $color-white--fixed;
    }

    &__text {
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: $font-weight-normal;
        line-height: toRem(25);
        color: $color-white--fixed;
    }

    &__phone {
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(25);
        color: $color-white--fixed;
        border-bottom: 2px solid transparent;
        transition: border-color $duration ease-in-out;
        width: fit-content;

        &:hover,
        &:focus {
            color: $color-white--fixed;
            border-color: $color-second;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__socials-links {
        display: flex;
        gap: 15px;
    }

    &__social-link {
        width: 40px;
        height: 40px;
        background-color: transparent;
        border-radius: $border-radius-medium;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        transition: background-color $duration ease-in-out;
        border: 1px solid $color-white--fixed;

        svg {
            fill: $color-white--fixed;
            width: 30px;
            height: 30px;
            transition: fill $duration ease-in-out;
        }

        &:hover,
        &:focus {
            background-color: $color-white--fixed;

            svg {
                fill: $color-dark--fixed;
            }
        }
    }

    &__contact-link {
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(20);
        color: $color-dark--fixed;
        background-color: $color-second;
        border-radius: $border-radius-medium;
        padding: 0 25px;
        display: flex;
        align-items: center;
        gap: 3px;
        justify-content: center;
        margin: 0;
        transition: background-color $duration ease-in-out;
        height: 45px;
        text-transform: uppercase;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-dark--fixed;
        }

        &:hover,
        &:focus {
            background-color: $color-white--fixed;
            color: $color-dark--fixed;
        }
    }

    &__menu {
        margin-bottom: 64px;

        &__list {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -40.75px;
                z-index: 0;
                width: 8px;
                height: 8px;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }

            &:nth-last-child(1)::before {
                display: none;
            }
        }

        &__link {
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-normal;
            line-height: toRem(20);
            color: $color-white--fixed;
            transition: opacity $duration $timing;

            &:hover,
            &:focus {
                color: $color-white--fixed;
                opacity: 0.8;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        margin-top: 100px;
        &__container {
            gap: 59px;
            padding: 80px 10px 75px;

            &::before {
                right: -195px;
            }
        }

        &__infos {
            width: 280px;
        }

        &__opening-hours {
            width: 280px;
        }

        &__menu {
            &__item {
                &::before {
                    right: -21.58px;
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__container {
            gap: 40px;
            flex-wrap: wrap;

            &::before {
                right: -105px;
            }
        }

        &__infos {
            width: 300px;
        }

        &__opening-hours {
            width: 300px;
        }

        &__links {
            flex-direction: row;
            width: 400px;
            flex-wrap: wrap;
        }

        &__menu {
            &__list {
                flex-wrap: wrap;
                gap: 20px 68px;
                justify-content: flex-start;
            }

            &__item {
                &::before {
                    right: -38px;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        margin-top: 80px;
        &__container {
            &::before {
                width: 160px;
                height: 160px;
                right: -97px;
            }
        }

        &__menu {
            margin-bottom: 120px;
        }
    }
}
