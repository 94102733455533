.card {
  width: 100%;
  margin-bottom: 15px;

  &__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    min-height: 160px;
    padding: $card--padding;
    background-color: $card--bg;
    border: $card--border;
    border-radius: $card--radius;
    color: $card--color;
    overflow: hidden;
    transition: {
      property: background-color, border-color, box-shadow;
      duration: $duration--second;
      timing-function: $timing;
    }

    &--search {
      min-height: 60px;

      .card__head-content {
        @include flex($direction: column-reverse, $alignItems: flex-start, $justifyContent: center);

        .card__title {
          margin-bottom: 0;
        }
      }

      .card__description {
        margin-top: 10px;
      }
    }
  }

  &__image-wrapper {
    width: 350px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      transition: transform 0.5s $timing;
    }
  }

  &__link {
    &:hover,
    &:focus {
      .card {
        &__container {
          background-color: $card--bg--hover;
          box-shadow: $card--shadow--hover;
          border: 1px solid rgba($color-main, 0.2);
        }

        &__category {
          background-color: $taxo-bg--hover;
          border-color: $taxo-border-color--hover;
          color: $taxo-color--hover;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    position: relative;
    padding: $card-content--padding;
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
  }

  &__category {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $taxo-padding;
    background-color: $taxo-bg;
    border: $taxo-border-width $taxo-border-style $taxo-border-color;
    border-radius: $taxo-border-radius;
    font-family: $taxo-font-family;
    font-size: $taxo-font-size;
    font-weight: $taxo-font-weight;
    line-height: $taxo-line-height;
    text-transform: $taxo-text-transform;
    color: $taxo-color;
    transition: {
      property: background-color, border-color, color;
      duration: $duration;
      timing-function: $timing;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-bg--icons;
    border-radius: 100%;
    transition: background-color $duration $timing;

    svg {
      width: 26px;
      height: 26px;
      fill: $card--color--second;
      transition: fill $duration $timing;
    }

    &:hover,
    &:focus {
      background-color: $color-main;

      svg {
        fill: $color-white;
      }
    }
  }

  &__title {
    text-transform: none;
    font-family: $font-family--heading;
    font-size: $font-size--4;
    color: $card--color;
    border: none;
    margin: 15px 0 10px;
    padding-bottom: 0;

    &::before {
      display: none;
    }
  }

  &__description {
    color: $card--color;

    ul {
      list-style-type: circle;
      padding-left: 15px;
    }
  }

  &__publication-date {
    display: flex;
    align-items: center;
    color: $card--color--second;
    margin: 15px 0 0 0;

    svg {
      flex: none;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      fill: $card--color--second;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    margin-top: 15px;

    p {
      flex: 1;
      color: $card--color--second;
    }

    svg {
      flex: none;
      width: 26px;
      height: 26px;
      fill: $card--color--second;
      margin: 0 6px 0 0;
    }
  }

  //=================================================================================================
  // Image par défaut
  //=================================================================================================
  .image-wrapper {
    background-repeat: no-repeat;
    background-position: center;

    &.post,
    &.category {
      background-image: url($urlShort + "/posts-icons/post.svg");
    }

    &.page,
    &.transitions,
    &.pages-systeme {
      background-image: url($urlShort + "/pages-icons/icon-page.svg");
    }
  }

  //=================================================================================================
  // Squared cards appearance
  //=================================================================================================
  &--square {
    .card {
      &__container {
        align-content: flex-start;
        height: 100%;
      }

      &__image-wrapper {
        width: 100%;
        height: $card-imageH;
        min-height: 0;
      }

      &__content {
        position: initial;
        display: flex;
        flex-direction: column;
        padding: 0 $card-content--padding $card-content--padding $card-content--padding;
      }

      &__title {
        margin: 0px 0 10px 0;
      }

      &__infos {
        justify-content: flex-start;
        transform: translateY(-50%);
      }

      &__actions {
        top: 10px;
        right: 10px;
      }
    }
  }
}

//=================================================================================================
// Flexible ? Slider ?
//=================================================================================================
.card-embed-wrapper {
  margin: $gl-gutter-vertical 0;

  .swiper-container {
    padding: 10px;
    margin: -10px;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 15px;
    bottom: 0;
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    @include size(12px);
    background-color: transparent;
    border: 1px $border-style $color-dark;
    border-radius: $border-radius--round;
    margin: 0 5px;
    transform-origin: 50% 50%;
    opacity: 1;
    transition: {
      property: background-color;
      duration: $duration;
      timing-function: $timing;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $color-dark;
  }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
  .card {
    margin-bottom: 10px;

    &__image-wrapper {
      width: 280px;
    }
  }
}

// 960
@media screen and (max-width: $medium) {
  .card {
    margin-bottom: 0;
  }
}

// 640
@media screen and (max-width: $small) {
  .card {
    &__image-wrapper {
      width: 270px;
    }

    &__container {
      min-height: auto;
    }

    &__title {
      @include font-size(19);
    }

    &--square {
      .card {
        &__container {
          height: auto;
        }
      }
    }
  }

  .card-embed-wrapper {
    .swiper-container {
      [class*="col-"] {
        padding-bottom: 15px;
      }
    }
  }

  .card__content--category {
    align-items: baseline;
    flex-direction: column;

    .card__image-wrapper {
      width: 100%;
      height: 185px;
      min-height: inherit;
    }
    .card__content {
      margin-top: $card-categorie--margin;
      padding: $card-content--padding;
    }
  }

  .card-embed-wrapper .swiper-pagination {
    margin-top: 10px;
  }
}
